import React from "react";
import {
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { AddArticleImg, sendIcon } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "./../../hooks/hook-form/FormProvider";
import { createCategory } from "../../slices/helpdesk";
import Input from "../Input";
import { openModal } from "../../slices/modals";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
const AddArticle = ({ id, open, handleClose, data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "#fff" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const defaultValues = {
    name: "",
    description: "",
  };

  const addArticleSchema = Yup.object().shape({
    name: Yup.string()
      .strict(false)
      .trim()
      .min(2, "Must be longer than 2 characters")
      .required("name is required"),
    description: Yup.string()
      .strict(false)
      .trim()
      .min(2, "Must be longer than 2 characters")
      .required("description is required"),
  });

  const methods = useForm({
    resolver: yupResolver(addArticleSchema),
    defaultValues,
  });
  const { user } = useSelector((state) => state.user);

  const onSubmit = async (values) => {
    try {
      dispatch(
        createCategory({
          websiteID: user?.websiteID,
          name: values.name,
          description: values.description,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          reset({
            defaultValues,
          });

          showSnackbar({
            variant: "success",
            message: t("Article Category successfully created"),
          });
          handleClose(id);
        } else {
          showSnackbar({
            variant: "error",
            message: t(res.error.message),
          });
        }
      });
    } catch (err) {
      showSnackbar({
        variant: "success",
        message: err.message,
      });
    }
  };

  const { handleSubmit, reset, resetField, formState, register } = methods;
  return (
    <Dialog open={open} onClose={() => handleClose(id)} className="info-modal">
      <LazyLoadImage
        src={AddArticleImg}
        className="assign-icon"
        alt="AddArticleImg"
      />
      <DialogTitle className="assign-operator-title">
        {" "}
        {t("Create a new Category")}
      </DialogTitle>
      <DialogContentText className="assign-operator-text">
        {t(
          "In order to push your article to helpdesk you need to setup all this preferences"
        )}
      </DialogContentText>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div id="select-category">
            <Input
              label={t("Name")}
              placeholder={t("Name")}
              name={"name"}
              errors={formState.errors}
              register={register}
            />
            <Input
              label={t("Description")}
              placeholder={t("Description")}
              name={"description"}
              errors={formState.errors}
              register={register}
            />
          </div>
          <DialogActions className="assign-operator-btns">
            <Button
              className="assign-operator-cancel-btn"
              onClick={() => {
                reset({
                  defaultValues,
                });
                handleClose(id);
              }}
            >
              {t("Cancel")}
            </Button>
            <button
              className="assign-operator-change-btn"
              color="primary"
              type="submit"
            >
              <LazyLoadImage src={sendIcon} style={{ marginRight: "5px" }} />
              {t("Create")}
            </button>
          </DialogActions>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default AddArticle;
